<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Penerima Donor'">
          <template v-slot:body>
            <Form :form="form" :route="'donor-recipients'" />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Form from '@/component/donor-recipients/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      form: {
        name: "",
        blood_type_id: null,
        age: null,
        birt_date: null,
        receipt_date: new Date().toISOString().substr(0, 10),
        phone: "",
        gender: null,
        quantity: 1,
        email: "",
        id_card_number: null,
        address: "",
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bank Darah", route: "/blood-bank" },
      { title: "Daftar Penerima Donor", route: "/donor-recipients" },
      { title: "Tambah Penerima Donor" },
    ])
  },

}
</script>

<style>
</style>